import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
export const ProjectQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(
      relativePath: {
        eq: "project/parabolic-motion-simulation/images/banner.jpeg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  ProjectQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Context`}</h2>
    <p>{`This was my final Project in second semester for the subject of algorithms and
programming in which I learned to program for the first time. During this
semester of electronic engineering students also see other subjects as calculus
and physics, so the teacher's intention was to articulate the topics seen in
those subjects with the schedule.`}</p>
    <p>{`During this semester I discovered my passion for programming due to the ability
it gave me to build tools, among other things, that had a real impact on real
life. Also, since I liked physics from my time of school, during the realization
of this project I was very motivated, so I was able to carry out a very good
project which turned out the best in the class.`}</p>
    <h2>{`Project construction`}</h2>
    <p>{`After having defined that the topic of the project would be parabolic motion, I
was looking for a theme for animation in which you could reflect this physical
phenomenon. After searching for a while, I decided to use as example the
movement of a cannonball.`}</p>
    <p>{`The teacher recommended us to use a library called `}<strong parentName="p">{` Vpython `}</strong>{`, which is a
Python programming language extension that brings a 3D graphics model called `}<em parentName="p">{`
"visual" `}</em>{` which offers us the ability to perform a large variety of models and
animations.`}</p>
    <p>{`Because I had worked with 3D modeling software before, It wasn’t very difficult
for me to learn how to model through code. Next you can see the code to create
the cannon as such:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b425b11a5782cef138c90fba12bbd06/a878e/canon_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVElEQVQoz4WTW2/TQBBG10l9ie20SbyOE99y8yV27FybNElTBAX6p/kFvPIGlQgg8QaVipEQovYwdqtQ9QVLR7Ozlo5nP60JITFpag2uXDuuEEEWmCoV+JO2UJIUoSTTojKy+gAt9ojYeEqFSJQnfJXgw4p6f/hG6A4/E8V7z6jeNaHuNcnrAfcJj995H5A97r9FYS030qo1/Vrtb6DmbOCkuwSuPQKm6UFZ87H6UNJCKLdygoJ87zEkR3V/ErZiFkJOjz9KvRUKz/6ozjKlziKlg3laH8zSWn+RivYsZY1JypvjlDeiFCdC3BRPkq/vmHvht4NQ7Cz3WnwJerBNqT2CqjWGWncGshGCZMbA6jEctXMi4M0IjrBnjTFInSlO7+dSYFT35iAUrNle6q0hn0IwJyBbEYhWjOIIKtYMhM4S5N4pyJ05iPYUmQPbDvBDIQqD9GHCf0LOGO9xSqh0TtO6dwHt4TnY8Q60YAdK+BpoeAWdxSvQwufQ8rdg+itQujHU7QgwKoxiCoQ6Nwwn3gt5c4IZnoHYW901vIus5W0yM9xkqr/NlPAqo6OrzJ6/zJrDZ1lruMssb5nJZpjJ+jAT7NldIVTd73hhDkf+Uu2vccIFHDtboO4GzNEWFG8L9eElNIJLMCYvQPF3oLpraDpLrCvQ/DM4Gawx21EuvGU4qRAqR63wHa9Hv/Ga3LLtUYLrRLKihDdiZJxgJIloj4uea4dJRQ8SyQgSzDvvfxA6+MVQ5xNhBZ2UyixH8t+FkB0hpXOs/4F5xENf5nakzE6xF/4ChAfcusn4H4AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Canon Code",
            "title": "Canon Code",
            "src": "/static/4b425b11a5782cef138c90fba12bbd06/c1b63/canon_code.png",
            "srcSet": ["/static/4b425b11a5782cef138c90fba12bbd06/5a46d/canon_code.png 300w", "/static/4b425b11a5782cef138c90fba12bbd06/0a47e/canon_code.png 600w", "/static/4b425b11a5782cef138c90fba12bbd06/c1b63/canon_code.png 1200w", "/static/4b425b11a5782cef138c90fba12bbd06/d61c2/canon_code.png 1800w", "/static/4b425b11a5782cef138c90fba12bbd06/a878e/canon_code.png 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9874266ee0ccebfb2c80de7e94a4d9a8/0bdcb/canon.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABcSAAAXEgFnn9JSAAABdklEQVQoz42RzW6CQBSF55lM+gpuu/YFuvYFuqy7srBJS9KVb9LUhYltUoGFq1oTEhwsyAwaIMzP7cwAitq/gS/D5F7OnJuDfN+/KoriLc/zJ8X4O3b5bpzl2finuuJZaUyiKBqhMAxv4Y8l1POftd1u3xHG+EYfpJSFgrcRQnDOOC9Fab5P6y1KrZGm6QzhMBwYQQAGlXK9yXOnQiqEwdQVskLUgh7Cq9XAFIRgZm+JrddreJlOYekvIYrTswuaK48Fg+AgyDlIBSsK03g3HAJCCDqdC+h2u9Dr9aDf74NlWfCpLjNi+h9tWQtS6h2NLOuRG4ebTQyTySvMPBdczwHHccB1XZjP55BnGdRGTgQxHtS2mTSrmdqo/oqEfW9r5FbKUAVj0JNzLpnOueQl44IzZWSPNmB6KyOtlDG2mlQbU0rIJGoMqFfp1qke+k6pBT/QYrG4VB8jQsi9wk6SCkqJrc+UUDsmsZ2QxJx/4IFS+hgEwfUX5186vRob1egAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Canon",
            "title": "Canon",
            "src": "/static/9874266ee0ccebfb2c80de7e94a4d9a8/c1b63/canon.png",
            "srcSet": ["/static/9874266ee0ccebfb2c80de7e94a4d9a8/5a46d/canon.png 300w", "/static/9874266ee0ccebfb2c80de7e94a4d9a8/0a47e/canon.png 600w", "/static/9874266ee0ccebfb2c80de7e94a4d9a8/c1b63/canon.png 1200w", "/static/9874266ee0ccebfb2c80de7e94a4d9a8/0bdcb/canon.png 1579w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Inspired by multiple cannon images on the internet I decided to build a cannon
with a wooden base, since the library also allows you to define materials and
colors to surfaces.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2c4fcc5c0fdca52ff1b8f278a75de645/813c1/base_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUklEQVQoz12S2W/aQBDG1xeXCYHgAwPmMLaxwSacCSYkadRIkfqX961vfSqtVCWV4pf2NQq70/GhNKqln3ZndvfzN7NLCGlxqjn0RMNckoY151V7wSujd2CsOoskL6RrdjrncI00rYQ5skR8Uq7zBL/TljX5WrVDIE3nhSj2K1EcxM1J5nZG0/4Xq+4rp6W8cNoYONX5TgrVs0RQk4zgR7m3BqkdskJnBnI3gEpnklLqzqBoLpEFVPqLLO7OQWp5QFQH8AcsG+1fRKq0E0G1ZC4OJ9YOZCs6Nv07NvAuWN9dsoEzZy3/mmnhJ2aE98xe3bO2v2da8MAU+4KJ7YAVO8FRas9A0L1HwksG6nGq0PIPSVI0AipbW9BGK9AGIejDEBrWGmrOHhrOFWjjCHQvglPnGqqDTbIf3YZU6qSCT4QXM4fF7vmhakUgD9b0rD9HkRXUnR1o1gLq9g5q9h6SQ3gYCPYLy8zISqZ5yU9vJWPfDpXhJZR7S6qMLtDJHozwA5jeBvTJHSjTB6ii86Z7CVgNCrmQXkQGzS8FBctvDr/lDo/qaE01Z0u1yS3tjVdU9W7omf+RlnorejJcUl730JFLOYRkHBNBdPmIgkZ6y+jwpzzcJg6hbm3A8K6gNb0B049A865Th0kf684mdzh+7xBywTh3yCmC5n6WjOkfUfeey+1pXDVnca1/HtfMIJZxLvfXcal7Hle60xgfdUyao5gobzwjv/FhfyFiSSccLxQI4aeovEPxLeGkKEOIMI+jiCPJYrEYEaHwP1tkh2sB7iv+BfOD4Z1YWqEcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Code Base of Canon",
            "title": "Code Base of Canon",
            "src": "/static/2c4fcc5c0fdca52ff1b8f278a75de645/c1b63/base_code.png",
            "srcSet": ["/static/2c4fcc5c0fdca52ff1b8f278a75de645/5a46d/base_code.png 300w", "/static/2c4fcc5c0fdca52ff1b8f278a75de645/0a47e/base_code.png 600w", "/static/2c4fcc5c0fdca52ff1b8f278a75de645/c1b63/base_code.png 1200w", "/static/2c4fcc5c0fdca52ff1b8f278a75de645/d61c2/base_code.png 1800w", "/static/2c4fcc5c0fdca52ff1b8f278a75de645/813c1/base_code.png 2004w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ec1b16183d2259a07fc501c76d9da663/f98ee/base.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcSAAAXEgFnn9JSAAABeklEQVQoz31SPUsDQRA9iDZCCCiCYGGT/yPY+A8EbawEG7GzSOcPUII5C8kH2ImBIAGbgIWgnIIfEQl3gRS3twfmbnd2nN29kwQSFx6zO/N483Z2Hd/3d6MoajLGLghuFDFXx9HIRqqZGLLQjZjNMZ3Loc9hWIs4rwdBcOhwzls4Y0mJKASiUvasUE0TdAHAEjNSHMf3jnZm62pMEDkoY2KaKpEkUoyTHyGTRCghLKQUCiDnJ1qDzN042nYmKJSNpiNIQJESDyTOW+rPrJKZYFs7tILUzVyBoOS0iO8P8NKtYrPeoDGAaZo3zvYTgmHomoK1T3pgRL76n1irnuP21iaWN9ZxbaWIy0sFvG41TD1NpRWd4bCWJVMCgFWEj/c32N/bgeKiA6WCA6uEcmkBHh96pi6FJKYysDM3grf6la/mzej1xcNup42nJ8dYOTrAXrfz7zDple8c+jsV2nyT8BNFLwf9P488eMSbAvG8SZ7l8mfO434wHJ79AinhkmXjhceJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Base of Canon",
            "title": "Base of Canon",
            "src": "/static/ec1b16183d2259a07fc501c76d9da663/c1b63/base.png",
            "srcSet": ["/static/ec1b16183d2259a07fc501c76d9da663/5a46d/base.png 300w", "/static/ec1b16183d2259a07fc501c76d9da663/0a47e/base.png 600w", "/static/ec1b16183d2259a07fc501c76d9da663/c1b63/base.png 1200w", "/static/ec1b16183d2259a07fc501c76d9da663/d61c2/base.png 1800w", "/static/ec1b16183d2259a07fc501c76d9da663/f98ee/base.png 1808w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then the wheels of the base, here I developed a little more my spatial
orientation and symmetry skills.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a0de0fefdc638b1b8653b333b3b10964/bcec6/wheels_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbUlEQVQoz42Sy2rbUBCGR3Zi2fL9KlmyrIttWbYsRZKV2LVDmhJoSAPdtYW+QB6g0HUhgTxA36EJKV2VvlBA63YZoojpSHFJoZsKPuY/w2HO/x8dOLNX8PHTAsCcADQcgO4cgPcA2hZAyyVNNEbUp6osAfpLYLQVMOqGRFOfkVxg9H0AUTDOJMv4Drx+DY3BDTSHGwYbSDf0G2iRbo1SmDbVJ66IH9DUXzNyAMAJ5rfqwMe8MkdWcpCTLCz2Zsj2XKzINhakGRb6AW6LNjK8iRmC6Yz/ARraJTM5AaANX1g5QE7du+P6XsQbq4ifHESSexRp00XUmR5GvHMaVY3DqG4so6ruR5QkgrYRkduk3kE6UDnP+O8Bsh3jipU9zPXcBwI5clqUHSz3E2wskUuO1gVlF4vaEit6gGVtTs5tzPd9LIhWlDqsKxew8wZgW5hel/RnWNKXDwTy1hF2zTXKs31Up4tUd6cvsEP9jn2Mskf4p9ger5HrpwaivDglh+pFxnuXOrxOHOZl/yHfc7CmBVjT97A9WWNnGGB9uMCa8Tx1V9J8GrKD8OfeWqNEPzp8Gji+KihBMvCe7c/j8mAVV1UvbupuXFcdwosr2jyuDPfjmnEQ00FxfbiMy2oQF5Ugpp94v4l8Du7bNPLXkr7CTWzkKR5PcWT7AJXpHkVeoUC99uwlRT5BJThGZf4K28Yak2Sc7OKWYNJA9RIcusNsa/CZFe2f9GRuc4IZcsIoLHTHYU60w6JohvmuGbLSDq1nYa5rkbbCsmyFW51RSE8l4Zbe6C+oyR+yHjmkTyaCv9j9Xxjazzxqn+CZTJb5DaCn5KoVxhBlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Code Wheels of Canon",
            "title": "Code Wheels of Canon",
            "src": "/static/a0de0fefdc638b1b8653b333b3b10964/c1b63/wheels_code.png",
            "srcSet": ["/static/a0de0fefdc638b1b8653b333b3b10964/5a46d/wheels_code.png 300w", "/static/a0de0fefdc638b1b8653b333b3b10964/0a47e/wheels_code.png 600w", "/static/a0de0fefdc638b1b8653b333b3b10964/c1b63/wheels_code.png 1200w", "/static/a0de0fefdc638b1b8653b333b3b10964/d61c2/wheels_code.png 1800w", "/static/a0de0fefdc638b1b8653b333b3b10964/bcec6/wheels_code.png 1834w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9a4827132bf3a9a66a14a6a45f0cab80/575bd/wheels.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcSAAAXEgFnn9JSAAABiElEQVQoz3WSMUvDQBTH4+AgCoKIxSJWB3HxOzgpdBF3v4ZQhC5dFCdHHRT36uCinapFLJU6SEEL2StSC21pLneX5N493yVpK7V9yY8jd+/+7//uYnU6nTPP82pSirKUsjIOLgXBh3OMVSQffgshXmj8bLfbGYtzXkIKpSIAELXGkdDhY14MgogxwRi7tlzXvQ+3aO0RCkArpf6CyvN9BUGglPTC0dSnAsrkx0ij4TjOuREsxIIqsjC0B2QX/MiNEHI4ryDsIupEm/BjwYsRwViOMkGpgUD+Jo/p9C6enhxj6bGI0RFRMdAGTUwQNCXJlY7FmNPDXPYI52amcWF+FlPJBD6XnmLBfsHIodna60WCD+E0gE+AIQgCMJ1lM4dgWRasJZdgY3UZFqcsKNzdhmuKckiojzc4Q8F58d91xdf8UXvHvZ1t3Fxfwa1UAg/20/j91cBJQeaurFardem6vOFyXqdfyO7DmGtTjt3tdu3XStmuvlVtlwubegjX/uY5DqvT2Gw2f3K/MwqPcEDEhTQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Wheels of Canon",
            "title": "Wheels of Canon",
            "src": "/static/9a4827132bf3a9a66a14a6a45f0cab80/c1b63/wheels.png",
            "srcSet": ["/static/9a4827132bf3a9a66a14a6a45f0cab80/5a46d/wheels.png 300w", "/static/9a4827132bf3a9a66a14a6a45f0cab80/0a47e/wheels.png 600w", "/static/9a4827132bf3a9a66a14a6a45f0cab80/c1b63/wheels.png 1200w", "/static/9a4827132bf3a9a66a14a6a45f0cab80/575bd/wheels.png 1791w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the same way I built other parts of the cannon, until I reached a result much
more realistic.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7bd441a8cb1bc724c7d559c107044634/a878e/details_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqElEQVQ4y42TWXOiQBSFG0ERdwXEBVFZRMEFdyVkTCp5mB8/fyAvcarmaSrMUnkNcOdiokmmMsk8nLpdTfV3zz3dEEIKlKKqZqqlTEm+NSElxSFlFdVxHtddhxRbKNkhefmwT/H4/VkT1BRlEa6cIIQkCpJq3XDdISQk64GpWUFS6gW01A+Y2iBgJDOga3bA1O0g1bBx3wyI2AsoFHnUAwpQ3wmbExFIxKzqfq32dyAMLiK+fwlSbwuV3jlI1g5q5hJSsgNpZQ5FdQbYJD4MVNU8KoorEY1fJMnJMVAo6N5tzb4C0boKJPs6qhqbSBhcRrL9Kaqa6yjdXkUFbR1V9Hl8OELgqaLCJ+CPEzDTWe95w4WS4YVldFbWN1DUPRBip8YS6PoI0i0H2Kb9tzt4Afx5Aqaa4z2nLOKxwpKGEHWB8DWI+hJKGsKNc+DNM8irq/jgx0Dsvs9218B1lqHQvzhkKFke1AceSAjirWtoDncgDnbxxb1y+SaQlScHYLa9CHltBRXzEzTtGLYBwf4MeS12twCuNQIiGEfQOyM3RnuuvcScpmGpM4VCdwk8jlxR5xA3Ylsz4JQ4w+H/ZZhEYKY9h4wyDYuYWQUvSOyfQdVYQaazhLhZQcOqjE8OP8hwti8ipNzbhnzPg6rpQWN4Ac3+FnLdFV7WAuvrkd8F0jXrNiWPgZVHQa67QZduKPY3Ia+vQnxSIddZhQUN18o4RGAMCGPQk4InoH8EiqzsfMupLmRxPB5zK+lbfIN4Idr8kF/8p/wrwxdO749Anq6aX5J1+z5ZG9xl5ZGfa8/8sjr188rYT8sTn21O/IyCtTHwCa/5lKD7RNCOuiOC/hv3bwiTbhAqQacIxYwIoTxCaJdQSfdQD+uUS2jWJQz7XJn0WzpDdw4y0n8AQ0tBCfXJODAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Code Details of Canon",
            "title": "Code Details of Canon",
            "src": "/static/7bd441a8cb1bc724c7d559c107044634/c1b63/details_code.png",
            "srcSet": ["/static/7bd441a8cb1bc724c7d559c107044634/5a46d/details_code.png 300w", "/static/7bd441a8cb1bc724c7d559c107044634/0a47e/details_code.png 600w", "/static/7bd441a8cb1bc724c7d559c107044634/c1b63/details_code.png 1200w", "/static/7bd441a8cb1bc724c7d559c107044634/d61c2/details_code.png 1800w", "/static/7bd441a8cb1bc724c7d559c107044634/a878e/details_code.png 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e6ed15248cb6fa1bb77c5903dc5dab91/807a0/details.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABcSAAAXEgFnn9JSAAABfklEQVQoz61SPUvDUBSN4KAuTv0TgoMOgn9AhDooOri4KggKFQcRwdnJwa8iovg/tCDo4qJk0ZpUB01rKk2avBSJL/dd73tJW1uMkyGH3I9zz70372kPRXPeqlTP38r2ISGfhtfye/6P/BFpnD6ZLzmtXnfP8J+eIGC3Wq1W25OOEOKTwH8DAHBObyuG2M0JpYbneRea4zgHiSAl1PdHTxVoe2QLABScx3bCJ0Qy7/t+oSUIIHgUCewExamoalfQdT6UKIQ0jIB2Q0wRlBPGBGx1hghUKL+7g7mlBSwVH1U+ojgNqpBwo3jlLkGRrIVKjMfiVLW6vIhjI0M4Nz2FpmHEk8rV43Xldh2C+0lhSAAJkCNQTck0YHx0GPp7NcgMDkCmrwdurgoqFxGHxJrgyaFcaq7jHKddg7rr4PbGOs5kJ3E2O4FbaytoV8qp14Yxdq1ZlrXZaDSeybkLgkBvgvm+HoahTjydMV93PVfZX5zr9PP1Di5j96RRtG375Bt2jt+nJmepTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Details of Canon",
            "title": "Details of Canon",
            "src": "/static/e6ed15248cb6fa1bb77c5903dc5dab91/c1b63/details.png",
            "srcSet": ["/static/e6ed15248cb6fa1bb77c5903dc5dab91/5a46d/details.png 300w", "/static/e6ed15248cb6fa1bb77c5903dc5dab91/0a47e/details.png 600w", "/static/e6ed15248cb6fa1bb77c5903dc5dab91/c1b63/details.png 1200w", "/static/e6ed15248cb6fa1bb77c5903dc5dab91/807a0/details.png 1652w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And as a result:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4752a5f25ca44629851b9778d1694c7e/c92c5/final_canon.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABcSAAAXEgFnn9JSAAABb0lEQVQoz41SPUsDQRBNZ5NGFGxFLSSFIvgP/A+HYCX+gtgYo0ZiIxFCyhTmPwg2WohgqliFU6KFxKuuyX0ldxc473Z2nN3LF5IzmeXdsm/fvJ3Z25RlWVXDMMqmaVaS0DE7lf/2CWXyqGqatpfCOYLTmCe63W5RGnLOA0IkQCuJ4ZoxiAIajLERR/Yx+EgXAADatp0bGgpS1sIYKUkeRdH0ailRiLjYpxweJ8p8Msz/MUQEEKI4OQwBWx+fWLopYe4kh9FPIASTZsmGg1ka9ft9rNVuMZs9RkVRcDuzjocH++i6PhUXHzi+1SRDcbLol8Lr9bD+UsfXRgOvCme4u5PBrc0NvDzP01UAxl3zQTdjQ8cRhqJsgJAARNJHyFHiuliApfQCrK4swtpyGh7u7yRPP0hqpR4kQqJEhaczn03r/Q2fnx5Ra3/NfDaO41ykfN9vu67b9DxPnQRxcqYqVOpJpXcz4qagSXvfuq4f/QI3td58ru+g7wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Final Canon",
            "title": "Final Canon",
            "src": "/static/4752a5f25ca44629851b9778d1694c7e/c1b63/final_canon.png",
            "srcSet": ["/static/4752a5f25ca44629851b9778d1694c7e/5a46d/final_canon.png 300w", "/static/4752a5f25ca44629851b9778d1694c7e/0a47e/final_canon.png 600w", "/static/4752a5f25ca44629851b9778d1694c7e/c1b63/final_canon.png 1200w", "/static/4752a5f25ca44629851b9778d1694c7e/c92c5/final_canon.png 1789w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Finally, implement the equations that describe the motion in question`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "589px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4cbd6151d356236ee4d6d8b7c545e41a/b1665/motion_equations.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABcSAAAXEgFnn9JSAAACGUlEQVQ4y61T2aoaQRT0+YLgGFRQR7/NDZffCeQ5kF/I5SaEkE8QxQcX3HDf973HTtXBFi/3kockDUXP6emqqeo+48pms14gkk6nbYNMJmOnUik7mUzekUgkBLFYzI7H4wLW3E9+Lpf7oLV2uer1+ufNZrNcrVYdoGeAtVfYbreC/X7f2+12Atbkkd9oNL6IYL/f/67fGcfjUYOgsVmDpBeLhV6v13o0Gun5fC71dDq97x8MBj9FsNvtfuXC9Xo9Aw4HSgcEBwIORB0QnVqt5sCFoNVqOYVCwQHXAedCfq/X+2YEn28fUfovBgSFd0vqckFZBC+XizqdTppgXBP7cDhomjbrEJDorIfDId+9FkT2Z26cTCZqNpvp8XisK5WKALEExWJRl8tlOT8Kcc7n84x5T/bGocLgTDd8NC45zuezOKU74xy3/X7kxzM0hH86QyNoXlDUCDMSz8scBW5XwPjValV3Op23kR8FjZgBe5CCjMyYFGUPsi/Zg7yUPwr+l7Z5bGy2ABwoOFJwoOBEanSCQjyF21W4DAW3Cg2vIMJU59vxvJhf78fjFxmDURlzuVzKmTWbTUG73TYxJTbXHni/RBD99hEuGujDIlyV8MuVzGwAcgkfkRkXdAdr7C2Sj0v6JIKRSOTJtm0rHA57QqGQFQgELKxZfr/f8vl8Uns8Hsvtdlter9cKBoN3sCaP/Gg0+lQqlVy/AdglQVSdeI8jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Equationes",
            "title": "Equationes",
            "src": "/static/4cbd6151d356236ee4d6d8b7c545e41a/b1665/motion_equations.png",
            "srcSet": ["/static/4cbd6151d356236ee4d6d8b7c545e41a/5a46d/motion_equations.png 300w", "/static/4cbd6151d356236ee4d6d8b7c545e41a/b1665/motion_equations.png 589w"],
            "sizes": "(max-width: 589px) 100vw, 589px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/64dae92fed9c0c11e00b04b78d4fb141/21e8f/motion_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAADcUlEQVQ4y6WUz5LiVBTGbwIkhMD0DCRAQkJCBwhJCH/7v/ZUzWIewrUrq1zNC8w7zMKdbnS2U6VZaI2uLF9Ci6W+gw7pOn73kp5O26MbqfrVPRear79zzhcYff8Ze/nxU/bpy3PG/AVj5pyx/pKxdsqYd8Gk0Ufg6j/A5z5Od8vkzSeM0ZevWDgMP3eW0zfMDL5m7eA16wB+GpPXknkf9pBvwBtmjF+w4iXrVvS2FWyo5ixJcVak4mz6S5J6M2Lm9D1SNxSw4iwDwV+gpXDBmmol3x6NLuhJcPmnYqf7qjXfK4N0X7OivdwN95XeTMDMcM+M6R4CqKe3/CUEO8FbaGlCUOoEWbWfUCd8lpuzazKnl9RNnpGfnJE5XlJ3sqLHowU13DnVB/F9t+Y0LwR/EoIyBFlnlPE/Uq153hxu8aU5qYOIVDsmxYqo2p8JeA3X9wSl7j8EuUPZnGb14YY0d5lrg+QwM2NSUNTivVJ9Ry7OsqBmR5kVn5OTnOfmdEN1J8FCuLNYOJNxYoYHUMv9iOTeAdzzqhWTZJQEVTvJNDisDVa5irPhrkgZLKmBuuXMSXHxnpNiFCmp7lpwSIOo86a/pkp3UnJoJdmRf0pNd5HLJlpqBzwGB0RbpTZvR2EWGJNcjOK25SpfSnuU1eHCnj/Pu9EV9eIrsuKnVLPnxRfDB1ks8XApuIgtK2jZQGy8xTW1p9doeUtVMTs+z0jURVTenw9iI93GhrfRCfKWv6VHIz7PJWKTku7Mxew0PD0NZLAk9OEccof4z4gN8ued5HwheDruttjjdYhthmLLfOuVwum/tlztRVnDPyOQ823XsVHuUOGu3Jjq9oyawxgkpOOu2FHhbvLhHFa6YcZjUHNWuTJYIOAr0v0TanlrarkpNTy4R1S4Y6kXiyzy7Il8Iof8LhnjkkMI8lZ1xEZ3FtSCWMNd0tEwpSM8v00Ia94Jcfc8g43hinj2FEfkMVdhBnG7E6z1Zt9pmKE+XL/Tvc2NZsc3Sn92gzZukElwXIDaGINJmXei9c7xj+UZ/lD3TvkMSQPN40tqBVe4nwr04wtqjc6oM1qJ+Bx+D2cFh5oZwc93P19t/wtY/kM2x78qdrLTve1Oc9c7RGUH17if7B456e7xYLaTjWAHN2V+A7+zJ95X0KozSZIrKFyw+Z8MQfVvRzSy6Su3zp8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Implemented equations in python",
            "title": "Implemented equations in python",
            "src": "/static/64dae92fed9c0c11e00b04b78d4fb141/c1b63/motion_code.png",
            "srcSet": ["/static/64dae92fed9c0c11e00b04b78d4fb141/5a46d/motion_code.png 300w", "/static/64dae92fed9c0c11e00b04b78d4fb141/0a47e/motion_code.png 600w", "/static/64dae92fed9c0c11e00b04b78d4fb141/c1b63/motion_code.png 1200w", "/static/64dae92fed9c0c11e00b04b78d4fb141/21e8f/motion_code.png 1684w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Also, here I implemented a logic so that it was possible to configure the
initial conditions of the simulation, such as: initial velocity of the
projectile and cannon tilt angle.`}</p>
    <p>{`Then we assign the motion variable to the position of the projectile `}<em parentName="p">{`et voila`}</em>{`
we have our cannon simulating the parabolic movement.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7030477e1caeaf8b70e55b6600030482/1d499/ball_code.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzUlEQVQoz5XSv2/TUBAH8Hu2nxMakRDbSRy7Ddiu7dTOjzaiCEWhVSuV/ikslTqiqggmWPonwIy6MLCVTq0QYkCqYKuUFSFmFij14zjbCTCgqrX08Vn28+m+fgb80INvTx8DIgKdoPoVoUy1dBm/8rXq3neQRlvA5m5Tj3evwW2Fz8xueAIN/5jVgrcpuDT/iOonMLyHzFuB9FDKdnysuUsomRGCESAtQFYPrwR095W8+SRrWFDqwUHRXkQtXD2reYNEaoQJGP5ftVSQ0Iv/82PS8CV/NAbgACpoziHQzXq8llj9+1iZH2Jxbgm53UPF6qLS7NAU6eQ+JUjN59e1LM15VnV3X95+D8DShrp7mMatBKNEC4Z4I1xFPdrAansNtfY6GvEGlv0VNLw7WHGWkc8u4jW7izN2jEUrPi9YHZQMd9/YPfnT8I3UaGPJufvTjO4JPRiJkrMsys5AzMz2hNzsCJpU0KSCU+V2X6hWLNTmAonOeDNCRpGv73ycNvSOpAZN6A2x1BpkESVzARWSfZ882j+mcSfPppvy4CDbFBWqN58zw/vM68EpaLfGOSenu2N2sVPyBaqtvcILzH8bYpMe6afYpF5Bl5ggc/k3GCDgfO0Sw10AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "CanonBall",
            "title": "CanonBall",
            "src": "/static/7030477e1caeaf8b70e55b6600030482/c1b63/ball_code.png",
            "srcSet": ["/static/7030477e1caeaf8b70e55b6600030482/5a46d/ball_code.png 300w", "/static/7030477e1caeaf8b70e55b6600030482/0a47e/ball_code.png 600w", "/static/7030477e1caeaf8b70e55b6600030482/c1b63/ball_code.png 1200w", "/static/7030477e1caeaf8b70e55b6600030482/1d499/ball_code.png 1632w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`During the construction process of the simulation it cost me little time
implement the equations in an object so I had time to iterate the cannon design
to achieve a result that I was satisfied with, here are a couple of older
versions:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1160px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9aee3f85373cbfde9076bca8f9fc68e6/6be49/version0.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABcSAAAXEgFnn9JSAAAC/klEQVQ4y1VT608TQRDv3+EXPxgRCvKqUgoiBUQwRKC0QA2PIIqPEGLPBDEhBq0YTIxokBBBQ6JfQBOIqGgkKJKoUbCgRqm8Snv0AQ2vUB7X7t6Ou8cVcJJfdnZu5re/md1TuFyuBo/Hfd3tdpsZnE6nOeh7PB6z0+WUMD83b7Z8HzbTfLPX65W+ORy8BOqz+tsWiyVGIYoiMBMxhm2frpjuCSEQwEiKzXlc8Li1GQb630l7hBD4Bb+UE6yZmpq6oAgIAmCEBJbDsDA/h4I+TUY0Ha2sLKGJv79R39uXqK2lCa2vrSN6IM0hwVxBJjytYEqoYTdvJ831taQiW0uabtSQPyND7GiCMCLWsZ/EPj1OerqfkYnxcSm+21i9TFiuoOqg81ETNqaoyMm4A5B3JBLYmp8UBWZTBYwOfQbXrB36+96A1WqV2guOA2TmXYRnFCgQgMHeblxdqiMFSZFgOBoNBakqyNYoIY/uR759glcvnsNdcy2sLi9B0HbN+X9C+SJwQNggvR3tUKlPB31COOgSI+CiMQuGvgxCe0sj6KliY1ocPLxzE6bHx7aJKR3BW7ZDKG4NUuphyeuGJ/froSxDDbnxYXC5zADVZwvBoI2B7HglnIjdB/nJ0dBwtQpGqXq5TrqIycnJHYVMunxBks1Yf0FjbRUYNGGQow4FfXIU5FPS4uNqKEo7JMV0CUqoOXeK9L/uxmu+VbDZbP8TSh2IIgn4/UQ+mfz4OkDqKouIITGc0DEQQ3I0MaaqSGmGhhSnq0muJoxkRu/F16rKYMQyXB4k9MvvCbP2KekWqGhpvljAAz2d2GTMxDnqEJybGIF1iQdxgTYGl2TE4+y4/f6up23g2xDKFezFM3GMmPlY/mMYiEhgM7AJfnHrb/GtLEJX+wOoNByDwpRY+gqiIOtwCJhKdLC+5gO73XFewfN89ezsrMlhd3C8g+eoz9GYtDLM8HbOZrdxtukpbs67wPmxyHW0NnK0fU6vVXFJoXu4e7fqLi0uLV/5+OG98h/MwTQ4XHA6RQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Canon version 0",
            "title": "Canon version 0",
            "src": "/static/9aee3f85373cbfde9076bca8f9fc68e6/6be49/version0.png",
            "srcSet": ["/static/9aee3f85373cbfde9076bca8f9fc68e6/5a46d/version0.png 300w", "/static/9aee3f85373cbfde9076bca8f9fc68e6/0a47e/version0.png 600w", "/static/9aee3f85373cbfde9076bca8f9fc68e6/6be49/version0.png 1160w"],
            "sizes": "(max-width: 1160px) 100vw, 1160px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1150px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/79778d56a47e060df3c63ea1b1c00007/33d1d/version1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABcSAAAXEgFnn9JSAAACWklEQVQ4y4WTTWgTURDHJ90WoXopiUlJ1diaNtBDG9JLIxprDLSp0cRYL0UUld5E7FZC24NUL4qHRrAi+AHeVIJ3JeDFEu1FSAtF/CDNF60IVmkas7tv3/jekg0xaenAn9m3M++3M7PvQS6Xu5nP5yeZn2J+eiuxWMWnUqnpbDZbWev70ul0JB6Pm4EQgpTSLaWqKvK4oshMivZONx7T87hJkoSJRKIPeICvmZRqsUSl9h1XLBZT8vlcdY7M+bIsFxmwVwcSWjb2XPGlUokuLSbpw7k5OhuN0kKhQM+NhOnCwkctrqpEz+UVSgzorAVWWmBfxHA4hA2CgACAoyNBrDY+Bkq1tmk5vx6oz4ZbYXMTXT3daGwW8PDB3XjZbcFZ8TwmP7z7D0wURdu6Y4X3p8bQ09mCwz0WDNsB/fsBB4yAg8xPnO3Ht6+eYuHPOk/VNpRKf6X5+ff1QK18qYSPZq5iuLsZB8yAQwcAQ11NeMbRhIEOA3otDG4CvNDfhs/u3KCZr8vaGD8lF+tbrj4aa7kVfH43gqMuIx4rVxdyNGrwU3YBfVZATwvQYNcunBkLStHbk85t/zIhCi23Q9d/rtIXD27Ri24rPbEXqH8fg3QKNGBnOtSo+lkHHiNIvXvAWXsOSbX4uWDgynrj9y/y+vE9cuWojXhNQHytQIZsoJxsN6iBDqF4pE1wgn4DOHg7abdFliujKBY28M3LJ3j9dB+baQMOtxtw0GZAdyu4IJPJRNjdHGcSd1I6vSJ+//ZFXF37IbK+xM/LS+Kl445xrxUmfDbhmstsMP0Dx8YuMgzwRKkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Canon version 1",
            "title": "Canon version 1",
            "src": "/static/79778d56a47e060df3c63ea1b1c00007/33d1d/version1.png",
            "srcSet": ["/static/79778d56a47e060df3c63ea1b1c00007/5a46d/version1.png 300w", "/static/79778d56a47e060df3c63ea1b1c00007/0a47e/version1.png 600w", "/static/79778d56a47e060df3c63ea1b1c00007/33d1d/version1.png 1150w"],
            "sizes": "(max-width: 1150px) 100vw, 1150px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Conclution`}</h2>
    <p>{`During the construction of this project, I discovered the capacity that
programming give us to model real-world objects and simulate physical phenomena.
I consider that the methodology used by the teacher to teach the subject was
very good and I present this project as proof. Also discovered that the best the
way to learn, at least for me, is by building projects and growing in the
process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      